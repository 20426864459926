@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100vw;
  }
  #about
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100vw;
  }

  #portfolio {
    width: 100vw;
  }
}

.carousel {
  margin-top: 50px;
}
.carousel-inner {
  height: 100px;
}
.carousel-caption {
  color: #fff;
  top: 50%;
}

.footer-wrapper {
  background-color: black;
  color: white;
}

.marquee {
  --gap: 1rem;
  position: fixed;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll 60s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}